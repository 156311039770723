<template>
  <div>
    <!--begin::User-->
    <!--    <div class="card card-custom">-->
    <div>
      <div v-if="PlanMessage? true: false" class="card-header d-block border-0 pt-6 pb-0">
        <div>
          <b-alert show variant="danger">{{ PlanMessage }}</b-alert>
        </div>
      </div>
      <!--      <div class="card-header flex-wrap border-0 pt-6 pb-0">-->
      <!--        <div class="card-title m-0">-->
      <h3 class="card-label">
        {{ $t('items.composite_item') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <!--        </div>-->
      <!--      </div>-->

      <b-tabs content-class="mt-3" class="nav-custom-link">
        <b-tab :title="$t('items.basic_information')" active @click="toggleTab('basic_information')">
          <div class="row">
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 pt-5">
                    <div class="form-group">
                      <custom-upload-image-2 :upload="dir_upload" :start-link="'base'" :image-url="image_url"
                                             @file="listenerAttachment"></custom-upload-image-2>
                    </div>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('items.name') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.name" class="form-control"
                           :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('items.name')"/>
                    <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
                  </div>
                </div>
              </div>
              <div class="card card-custom mt-5">
                <div class="card-body row">
                  <!-- <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.initial_stock_level') }}</label>
                    <input type="text" v-model="data.initial_stock_level" class="form-control"
                           :class="validation && validation.initial_stock_level ? 'is-invalid' : ''"
                           :placeholder="$t('items.initial_stock_level')"/>
                    <span v-if="validation && validation.initial_stock_level"
                          class="fv-plugins-message-container invalid-feedback">
                                {{ validation.initial_stock_level[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.low_stock_threshold') }}</label>
                    <input type="text" v-model="data.low_stock_threshold" class="form-control"
                           :class="validation && validation.low_stock_threshold ? 'is-invalid' : ''"
                           :placeholder="$t('items.low_stock_threshold')"/>
                    <span v-if="validation && validation.low_stock_threshold"
                          class="fv-plugins-message-container invalid-feedback">
                                {{ validation.low_stock_threshold[0] }}
                            </span>
                  </div> -->
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.re_order') }}</label>
                    <input type="number" v-model="data.re_order" class="form-control"
                           :class="validation && validation.re_order ? 'is-invalid' : ''"
                           :placeholder="$t('items.re_order')"/>
                    <span v-if="validation && validation.re_order" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.re_order[0] }}
                                </span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.sum_available_qty') }}</label>
                    <input type="text" v-model="data.sum_available_qty" disabled class="form-control"
                           :placeholder="$t('items.sum_available_qty')"/>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.sum_stock_on_hand_qty') }}</label>
                    <input type="number" disabled v-model="data.sum_stock_on_hand_qty" class="form-control"
                           :placeholder="$t('items.sum_stock_on_hand_qty')"/>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.minimum_qty') }}</label>
                    <input type="number" v-model="data.minimum_qty" min="0" class="form-control"
                           :class="validation && validation.minimum_qty ? 'is-invalid' : ''"
                           :placeholder="$t('items.minimum_qty')"/>
                    <span v-if="validation && validation.minimum_qty"
                          class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.minimum_qty[0] }}
                                </span>
                  </div>

<!--                  <div class="col-lg-6 mb-5">-->
<!--                    <label>&ensp;</label>-->
<!--                    <b-form-checkbox size="lg" v-model="data.is_tracking" name="check-button" switch>-->
<!--                      {{ $t('items.is_tracking') }}-->
<!--                    </b-form-checkbox>-->
<!--                  </div>-->
                  <div class="col-lg-6 mb-5">
                    <label>&ensp;</label>
                    <b-form-checkbox size="lg" v-model="data.is_manufacture" name="check-button" switch>
                      {{ $t('items.is_manufacturing_item') }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.sku_code') }}</label>
                    <input type="text" v-model="data.sku_code" class="form-control"
                           :class="validation && validation.sku_code ? 'is-invalid' : ''"
                           :placeholder="$t('items.sku_code')"/>
                    <span v-if="validation && validation.sku_code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.sku_code[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.barcode') }}</label>
                    <input type="text" v-model="data.barcode" class="form-control"
                           :class="validation && validation.barcode ? 'is-invalid' : ''"
                           :placeholder="$t('items.barcode')"/>
                    <span v-if="validation && validation.barcode" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.barcode[0] }}
                                </span>
                  </div>
                  <div class="col-md-6 mb-5">
                    <label>{{ $t('items.category') }}<span class="text-danger">*</span></label>
                    <div class="input-group mb-3">
                      <treeselect
                          :options="categories"
                          :load-options="loadOptions"
                          :multiple="false"
                          :value="data.category_id"
                          @select="updateValue"
                          :searchable="true"
                          :class="validation && validation.category_id ? 'is-invalid' : ''"
                          :show-count="true"
                          :no-children-text="$t('No_sub_options')"
                          :no-options-text="$t('No_options_available')"
                          :no-results-text="$t('No_results_found')"
                          :placeholder="$t('Select')">
                      </treeselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/categories" target="_blank"><i class="fa fa-plus"
                                                                                                  style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.category_id"
                          class="fv-plugins-message-container invalid-feedback">
                                {{ validation.category_id[0] }}
                        </span>
                  </div>

                  <div class="col-md-6 mb-5">
                    <label>{{ $t('items.brand') }}</label>
                    <div class="input-group mb-3">
                      <multiselect v-model="brand" :placeholder="$t('items.brand')" label="name" track-by="id"
                                   :options="brands" :multiple="false" :taggable="true"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/brands" target="_blank"><i class="fa fa-plus"
                                                                                              style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.brand_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.brand_id[0] }}
                        </span>
                  </div>
                  <div class="col-md-6 mb-5">
                    <label>{{ $t('items.unit') }}<span class="text-danger">*</span></label>
                    <div class="input-group mb-3">
                      <multiselect v-model="unit"
                                   :placeholder="$t('items.unit')"
                                   label="name"
                                   track-by="id"
                                   :options="units"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/units" target="_blank"><i class="fa fa-plus"
                                                                                             style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">
                                                    {{ $t('type_at_least_three_letters') + ' ' + $t('items.unit') }}.
                                                </span>

                    <span v-if="validation && validation.unit_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.unit_id[0] }}
                        </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>&ensp;</label>
                    <b-form-checkbox v-model="data.is_min" @input="onChangeIsMin" name="check-button" size="lg" switch>{{ $t('minimum_unit') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.return_days') }}</label>
                    <input type="number" v-model="data.allowable_return_days" class="form-control"
                           :class="validation && validation.allowable_return_days ? 'is-invalid' : ''"
                           :placeholder="$t('items.return_days')"/>
                    <span v-if="validation && validation.allowable_return_days" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.allowable_return_days[0] }}
                            </span>
                  </div>
                  <div class="col-md-6 mb-5">
                    <label>{{ $t('items.tags') }}</label>
                    <multiselect v-model="data.tags"
                                 :placeholder="$t('items.tags')"
                                 label="name" track-by="id"
                                 :options="tags_list"
                                 :multiple="true"
                                 :taggable="true"
                                 @tag="addTag"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                    <span v-if="validation && validation.tags" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.tags[0] }}
                        </span>
                  </div>


                  <div class="col-lg-6 mb-5">
                    <label>&ensp;</label>
                    <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}
                    </b-form-checkbox>
                  </div>

                </div>
              </div>
              <div class="card card-custom mt-5">
                <div class="card-body row">
                  <div class="col-lg-6">
                    <label>{{ $t('items.special_description') }}</label>
                    <textarea type="text" v-model="data.special_description" class="form-control" style="height: 86px;"
                              :class="validation && validation.special_description ? 'is-invalid' : ''"
                              :placeholder="$t('items.special_description')"></textarea>
                    <span v-if="validation && validation.special_description"
                          class="fv-plugins-message-container invalid-feedback">
                                {{ validation.special_description[0] }}
                        </span>
                  </div>
                  <div class="col-lg-6">
                    <label>{{ $t('items.description') }}</label>
                    <textarea type="text" v-model="data.description" class="form-control" style="height: 86px;"
                              :class="validation && validation.description ? 'is-invalid' : ''"
                              :placeholder="$t('items.description')"></textarea>
                    <span v-if="validation && validation.description"
                          class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                        </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="row mt-5">
            <div class="col-md-12">

              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('items.units') }}</h6>
                <button class="btn btn-primary mr-2" type="button" @click="addUnitToRepeater">{{$t('add_more') }}</button>
              </div>
              <div class="bg-white">
                <table class="table" @keyup.alt.enter="addUnitToRepeater" @keyup.alt.46="deleteUnitFromRepeater(0)">
                  <thead>
                  <tr class="text-center">
                    <th width="40%">{{ $t('items.unit') }}</th>
                    <th width="40%">{{ $t('items.number') }}</th>
                    <th width="40%">{{ $t('status') }}</th>
                    <th class="text-center" width="20%"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in data_units" :key="index">
                    <td>
                      <multiselect v-model="row.unit"
                                   :multiple="false"
                                   :options="units"
                                   :placeholder="$t('items.unit')"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :taggable="false"
                                   @input="onChangeUnitsRepeater(index)"
                                   label="name"
                                   track-by="id">
                      </multiselect>
                    </td>
                    <td>
                      <input v-model="row.number" @input="onChangeUnitsRepeater(index)" :placeholder="$t('items.number')" class="form-control" type="text"/>
                    </td>
                    <td>
                      <b-form-checkbox v-model="row.is_active" name="check-button" size="lg" switch class="text-center"></b-form-checkbox>
                    </td>
                    <td class="text-center">
                      <v-icon class="text-danger" @click="deleteUnitFromRepeater(index)">mdi-delete</v-icon>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row mt-5">

            <div class="col-md-12">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('items.sub_items') }}</h6>
                <button type="button" class="btn btn-primary" @click="addItemRowToList">{{ $t('add_more') }}
                </button>
              </div>
              <div class="table-responsive bg-white pb-5">
                <table class="table table-row-bordered w-100" @keyup.alt.enter="addItemRowToList"
                       @keyup.alt.46="removeItemRowFromList">
                  <thead>
                  <tr>
                    <th width="250px">{{ $t('items.item_details') }}</th>
                    <th width="300px">
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('items.item') }}</span>
                        <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal">
                          <i class="fa fa-plus add-new"></i>
                        </a>
                      </div>
                    </th>
                    <th>{{ $t('items.qty') }}</th>
                    <th>{{ $t('actions') }}</th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in sub_items" :key="index">
                    <td>
                      <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item"
                         class="d-flex cursor-pointer">
                        <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                        <div class="pl-2 pr-2 text-dark">
                          <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}:
                            {{ row.item.sku_code }}</p>
                          <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                          <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}:
                            {{ row.item.sale_price }}</p>
                          <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}:
                            {{ row.item.purchase_price }}</p>
                          <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}:
                            {{ row.item.sum_available_qty }}</p>
                        </div>
                      </a>
                    </td>
                    <td>
                      <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('items.item')">
                        <select name="" id="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                          <option value="name" selected>{{ $t('items.name') }}</option>
                          <option value="sku_code">{{ $t('items.sku_code') }}</option>
                        </select>
                        <multiselect v-model="row.item"
                                     :placeholder="$t('items.item')"
                                     :label="row.f_by? row.f_by:'name'"
                                     track-by="id"
                                     :options="items"
                                     :multiple="false"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false"
                                     :internal-search="false"
                                     @input="emptyItemsList"
                                     @search-change="getItems(row.f_by, $event)">
                        </multiselect>
                      </div>

                    </td>

                    <td>
                      <input v-model="row.qty" type="number" class="form-control" :class="validation && validation[`sub_items.${index}.qty`] ? 'is-invalid' : ''">
                      <span v-if="validation && validation[`sub_items.${index}.qty`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`sub_items.${index}.qty`][0] }}
                          </span>
                    </td>

                    <td>
                      <v-icon style="color: #dc3545;" small v-if="sub_items.length > 1"
                              @click="removeItemRowFromList(index)">mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>


          </div>

        </b-tab>

        <b-tab :title="$t('items.financial_information')" :disabled="idEdit == null" @click="toggleTab('financial_information')">
          <div class="row">
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.purchase_price') }}</label>
                    <input type="number" v-model="data.purchase_price" class="form-control"
                           :class="validation && validation.purchase_price ? 'is-invalid' : ''"
                           :placeholder="$t('items.purchase_price')"/>
                    <span v-if="validation && validation.purchase_price"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.purchase_price[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.sale_price') }}</label>
                    <input type="number" v-model="data.sale_price" class="form-control"
                           :class="validation && validation.sale_price ? 'is-invalid' : ''"
                           :placeholder="$t('items.sale_price')"/>
                    <span v-if="validation && validation.sale_price"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.sale_price[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.mrp') }}</label>
                    <input type="number" v-model="data.mrp" class="form-control"
                           :class="validation && validation.mrp ? 'is-invalid' : ''"
                           :placeholder="$t('items.mrp')"/>
                    <span v-if="validation && validation.mrp"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.mrp[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.min_sale_price') }}</label>
                    <input type="number" v-model="data.min_sale_price" class="form-control"
                           :class="validation && validation.min_sale_price ? 'is-invalid' : ''"
                           :placeholder="$t('items.min_sale_price')"/>
                    <span v-if="validation && validation.min_sale_price"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.min_sale_price[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.profit_margin') }}</label>
                    <input type="number" v-model="data.profit_margin" class="form-control"
                           :class="validation && validation.profit_margin ? 'is-invalid' : ''"
                           :placeholder="$t('items.profit_margin')"/>
                    <span v-if="validation && validation.profit_margin"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.profit_margin[0] }}
                                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.discount_type') }}</label>
                    <div class="input-group">
                      <select name="" id="discount_type" v-model="data.discount_type" class="custom-select">
                        <option v-for="row in discount_type_list" :value="row.id" :key="row.id">
                          {{ row.name }}
                        </option>
                      </select>
                      <span v-if="validation && validation.discount_type"
                            class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.discount_type[0] }}
                                        </span>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.discount') }}</label>
                    <input type="number" v-model="data.discount" class="form-control"
                           :class="validation && validation.discount ? 'is-invalid' : ''"
                           :placeholder="$t('items.discount')"/>
                    <span v-if="validation && validation.discount" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.discount[0] }}
                                    </span>
                  </div>
<!--                  <div class="col-lg-6 mb-5">-->
<!--                    <label>{{ $t('items.total_taxes') }}</label>-->
<!--                    <input type="number" v-model="data.total_taxes" class="form-control" disabled-->
<!--                           :class="validation && validation.total_taxes ? 'is-invalid' : ''"-->
<!--                           :placeholder="$t('items.total_taxes')"/>-->
<!--                    <span v-if="validation && validation.total_taxes" class="fv-plugins-message-container invalid-feedback">-->
<!--                                            {{ validation.total_taxes[0] }}-->
<!--                                    </span>-->
<!--                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-12 pt-5">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('items.taxes') }}</h6>
                <button type="button" class="btn btn-primary" @click="addToRepeater">{{ $t('add_more') }}</button>
              </div>
              <div class="bg-white">
                <table class="table table-custom-padding" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
                  <thead>
                  <tr>
                    <th width="50%">
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('items.tax') }}</span>
                        <a href="/settings/taxes/create" target="_blank" class="btn btn-primary btn-sm p-1">
                          <i class="fa fa-plus add-new"></i>
                        </a>
                      </div>
                    </th>
                    <th width="30%">{{ $t('items.percentage') }}</th>
                    <th width="30%">{{ $t('minimum') }}</th>
                    <th width="30%">{{ $t('items.apply_priority_tax') }}</th>
                    <th width="20%" class="text-center">{{ $t('actions') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(tx, index) in data_taxes" :key="index">
                    <td>
                      <multiselect v-model="tx.tax"
                                   :placeholder="$t('items.tax')"
                                   label="name"
                                   track-by="id"
                                   :options="taxes"
                                   :multiple="false"
                                   @input="handlingPercentageValue(index)"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                    </td>
                    <td>
                      <input type="text" v-model="tx.percentage" class="form-control"
                             :placeholder="$t('items.percentage')"/>

                    </td>
                    <td>
                      <input type="number" v-model="tx.minimum" class="form-control" :placeholder="$t('minimum')"/>

                    </td>
                    <td>
                      <select name="" id="apply_priority_tax" v-model="tx.priority" @input="onPriorityTaxChanged(index)" class="custom-select">
                        <option v-for="row in apply_priority_list" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                    </td>
                    <td class="text-center">
                      <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>

                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>


          <!--
                                         <div class="col-lg-6 mb-5">
                                             <label>{{$t('items.raw_material_price')}}</label>
                                             <input type="number" v-model="data.raw_material_price" class="form-control" :class="validation && validation.raw_material_price ? 'is-invalid' : ''" :placeholder="$t('items.purchase_price')"/>
                                             <span v-if="validation && validation.raw_material_price" class="fv-plugins-message-container invalid-feedback">
                                                     {{ validation.raw_material_price[0] }}
                                             </span>
                                         </div>
                                         <div class="col-lg-6 mb-5">
                                             <label>{{$t('items.purchase_price_last_3_invoice')}}</label>
                                             <input type="number" v-model="data.purchase_price_last_3_invoice" class="form-control" :class="validation && validation.purchase_price_last_3_invoice ? 'is-invalid' : ''" :placeholder="$t('items.purchase_price_last_3_invoice')"/>
                                             <span v-if="validation && validation.purchase_price_last_3_invoice" class="fv-plugins-message-container invalid-feedback">
                                                     {{ validation.purchase_price_last_3_invoice[0] }}
                                             </span>
                                         </div>
                                         <div class="col-lg-6 mb-5">
                                             <label>{{$t('items.purchase_price_last_6_invoice')}}</label>
                                             <input type="number" v-model="data.purchase_price_last_6_invoice" class="form-control" :class="validation && validation.purchase_price_last_6_invoice ? 'is-invalid' : ''" :placeholder="$t('items.purchase_price_last_6_invoice')"/>
                                             <span v-if="validation && validation.purchase_price_last_6_invoice" class="fv-plugins-message-container invalid-feedback">
                                                     {{ validation.purchase_price_last_6_invoice[0] }}
                                             </span>
                                         </div>
                                         <div class="col-lg-6 mb-5">
                                             <label>{{$t('items.purchase_price_last_10_invoice')}}</label>
                                             <input type="number" v-model="data.purchase_price_last_10_invoice" class="form-control" :class="validation && validation.purchase_price_last_10_invoice ? 'is-invalid' : ''" :placeholder="$t('items.purchase_price_last_10_invoice')"/>
                                             <span v-if="validation && validation.purchase_price_last_10_invoice" class="fv-plugins-message-container invalid-feedback">
                                                     {{ validation.purchase_price_last_10_invoice[0] }}
                                             </span>
                                         </div> -->

        </b-tab>
        <b-tab :title="$t('items.inventory_details')" :disabled="idEdit == null"
               @click="toggleTab('inventory_details')">

          <div class="row">
            <div class="col-lg-12">
              <div class="card card-custom">
                <div class="card-body">
                  <div class="bg-white">
                    <table class="table table-custom-padding">
                      <thead>

                      <tr>
                        <th>{{ $t('inventory_statistics.inventory') }}</th>
                        <th>{{ $t('inventory_statistics.product') }}</th>
                        <th>{{ $t('inventory_statistics.financial_year') }}</th>
                        <th>{{ $t('inventory_statistics.sales_booking') }}</th>
                        <th>{{ $t('inventory_statistics.available_stock_qty') }}</th>
                        <th>{{ $t('inventory_statistics.open_stock_quantity_total') }}</th>
                        <th>{{ $t('inventory_statistics.stock_on_hand_qty') }}</th>
                        <th>{{ $t('inventory_statistics.purchase_on_way') }}</th>
                        <th>{{ $t('inventory_statistics.max_quantity') }}</th>
                        <th>{{ $t('inventory_statistics.min_quantity') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(statistic, index) in statistics_item" :key="'statistic'+index">
                        <td>{{statistic.inventory}}</td>
                        <td>{{statistic.product}}</td>
                        <td>{{statistic.financial_year}}</td>
                        <td>{{statistic.sales_booking}}</td>
                        <td>{{statistic.available_stock_qty}}</td>
                        <td>{{statistic.open_stock_quantity_total}}</td>
                        <td>{{statistic.stock_on_hand_qty}}</td>
                        <td>{{statistic.purchase_on_way}}</td>
                        <td>
                          <input type="text" v-model="statistic.max_quantity" class="form-control"/>
                        </td>
                        <td>
                          <input type="text" v-model="statistic.min_quantity" class="form-control"/>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </b-tab>
        <b-tab v-if="idEdit" :title="$t('activity_log.activity_log')" @click="toggleTab('activity_log')">

          <div class="row">
            <div class="col-lg-12">
              <div class="card card-custom">
                <div class="card-body row">
                  <item-quantity-activity-log :id="idEdit"></item-quantity-activity-log>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <div class="pl-0 pr-0" v-if="tab_name != 'activity_log'">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save(1)">{{ $t('save') }}</button>
            <button v-if="!isEditing" type="button" class="btn btn-primary mr-2" @click="save(2)">{{ $t('save_and_create_new') }}</button>
            <button v-if="!isEditing" type="button" class="btn btn-primary mr-2" @click="save(3)">{{ $t('save_and_redirect_to_main_page') }}</button>

          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ItemQuantityActivityLog from "@/view/content/widgets/details/ItemQuantityActivityLog";
import {mapGetters, mapState} from "vuex";
import ItemForm from "@/view/content/forms/ItemForm";

export default {
  name: "from-item",
  components: {'item-quantity-activity-log': ItemQuantityActivityLog, 'item-form': ItemForm},
  data() {
    return {
      mainRoute: 'items/items',
      mainRouteDependency: 'base/dependency',
      key: 'Items',
      dir_upload: 'items',
      categories: [],
      brands: [],
      tags_list: [],
      taxes: [],
      discount_type_list: [

        {id: 1, name: this.$t('discount_type.percentage')},
        {id: 2, name: this.$t('discount_type.value')},
      ],
      apply_priority_list: [
        {id: 1, name: 1},
        {id: 2, name: 2},
        {id: 3, name: 3},
        {id: 4, name: 4},
        {id: 5, name: 5},
      ],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        name: null,
        category_id: null,
        brand_id: null,
        description: null,
        special_description: null,
        sku_code: null,
        barcode: null,
        image: null,
        tags: [],
        is_active: true,
        is_manufacture: true,
        is_tracking: false,
        re_order: null,

        purchase_price: null,
        raw_material_price: null,
        purchase_price_last_3_invoice: null,
        purchase_price_last_6_invoice: null,
        purchase_price_last_10_invoice: null,
        sale_price: null,
        min_sale_price: null,
        profit_margin: null,
        discount: null,
        discount_type: 1,
        initial_stock_level: null,
        low_stock_threshold: null,
        minimum_qty: null,
        item_type: 2,
        total_taxes: 0,
        mrp: null,
        unit_id: null,
        allowable_return_days: null,
        is_min: false,
      },
      isEditing: false,
      validation: null,
      tax_default: null,
      image_url: null,
      reloadUploadAttachment: true,
      repeater_taxes: {id: null, tax: null, percentage: null, priority: null, minimum: 0},
      data_taxes: [],

      items: [],
      sub_items: [],
      sub_items_repeater: {id: null, item: null, qty: null},

      brand: null,
      tab_name: 'basic_information',
      PlanMessage: null,
      unit: null,
      units: [],
      statistics_item: [],
      repeater_unit: {unit: null, unit_id: null, number: null, is_active: true},
      data_units: [],

    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    brand: function (val) {
      if (val && val.id) {
        this.data.brand_id = val.id;
      } else {
        this.data.brand_id = null;
      }

    },
    unit: function (val) {
      if (val && val.id) {
        this.data.unit_id = val.id;
      } else {
        this.data.unit_id = null;
      }

    },
    data_taxes: {
      handler(val) {
        if (val.length > 0) {
          this.calcPercentageForTotal();
        }
      },
      deep: true
    },
  },
  methods: {

    toggleTab(tab_name) {
      this.tab_name = tab_name
    },
    // checkUnitsWithoutZero(){
    //   this.data_units.forEach()
    // },
    save(_action) {
      if (this.isEditing) {
        this.update();
      } else {
        this.create(_action);
      }
    },
    redirectActionAfterSave(_action){
      switch (_action){
        case 1:
          this.$router.push({name: 'composite-items.edit', params: {id: this.idEdit}}, () => {
            window.location.reload();
          });
          break;
        case 2:
          this.$router.push({query: {_pgid: Date.now()}});
          // this.$router.push({name: 'composite-items.create'});
          break;
        case 3:
          this.$router.push({name: 'composite-items.index'});
          break;
        default:
          this.$router.push({name: 'composite-items.edit', params: {id: this.idEdit}}, () => {
            window.location.reload();
          });
          break;
      }
    },
    create(_action) {
      // this.data.is_active = this.data.is_active ? '1' : '0';
      // this.data.is_manufacture = this.data.is_manufacture ? '1' : '0';
      // this.data.is_tracking = this.data.is_tracking ? '1' : '0';
      let _units = [...this.data_units];
      _units = _units.map((row)=>{
        return {unit_id: row.unit_id, number: row.number, is_active: row.is_active}
      });
      _units = _units.filter((row)=> parseFloat(row.number) > 0);

      ApiService.post(this.mainRoute, {
        ...this.data,
        taxes: this.data_taxes,
        sub_items: this.sub_items,
        statistics_item: this.statistics_item,
        units: _units,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.idEdit = response.data.data.id;
        //   this.$router.push({name: 'composite-items.edit', params:{id: response.data.data.id}});
        // this.$router.push({name: 'composite-items.edit', params: {id: response.data.data.id}}, () => {
        //   window.location.reload();
        // });
        this.redirectActionAfterSave(_action);
        // if (response.data.data.id){
        //     this.getData();
        // }
        // this.$router.push('/items/composite-items');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      // this.data.is_active = this.data.is_active ? '1' : '0';
      // this.data.is_manufacture = this.data.is_manufacture ? '1' : '0';
      // this.data.is_tracking = this.data.is_tracking ? '1' : '0';
      let _units = [...this.data_units];
      _units = _units.map((row)=>{
        return {unit_id: row.unit_id, number: row.number, is_active: row.is_active}
      });
      _units = _units.filter((row)=> parseFloat(row.number) > 0);

      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        taxes: this.data_taxes,
        sub_items: this.sub_items,
        statistics_item: this.statistics_item,
        units: _units,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/composite-items');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data = response.data.data;
        this.brand = response.data.data.brand;
        this.unit = response.data.data.unit;
        this.image_url = response.data.data.image_url;
        this.data_taxes = response.data.data.taxes;
        if (response.data.data.taxes.length <= 0) {
          this.addToRepeater();
        }

        this.sub_items = response.data.data.sub_items;
        if (response.data.data.sub_items && response.data.data.sub_items.length <= 0) {
          this.addItemRowToList();
        }

        this.reloadUploadAttachment = true;
      });
    },

    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    getBrands() {
      ApiService.get(this.mainRouteDependency + "/brands").then((response) => {
        this.brands = response.data.data;
      });
    },
    getTaxes() {
      ApiService.get(this.mainRouteDependency + "/taxes").then((response) => {
        this.taxes = response.data.data;
      });
    },
    updateValue(value) {
      this.data.category_id = value.id;
      this.data.allowable_return_days = value.allowable_return_days;
    },
    loadOptions() {
    },
    listenerAttachment(event) {
      if (event) {
        this.image_url = event.pathDB;
        this.data.image = event.name;
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        id: Math.floor((Math.random() * 10000000))
      }
      if (!this.tags_list) {
        this.tags_list = [];
      }
      this.tags_list.push(tag);

      if (!this.data.tags) {
        this.data.tags = [];
      }
      this.data.tags.push(tag)
    },
    async getDefaultTax() {
      await ApiService.get(`${this.mainRouteDependency}/default-tax`).then((response) => {
        this.tax_default = response.data.data;
      });
    },
    addToRepeater() {
      this.repeater_taxes.priority = this.data_taxes.length + 1;
      this.repeater_taxes.tax = this.tax_default;
      this.data_taxes.unshift(this.repeater_taxes);
      this.repeater_taxes = {id: null, tax: this.user_personal_info.tax, percentage: null, priority: null, minimum: 0};
    },
    deleteFromRepeater(index) {
      this.data_taxes.splice(index, 1);
      this.data_taxes = this.data_taxes.map((row, index) =>{
        row.priority = this.data_taxes.length - index;
        return row;
      });
      this.calcPercentageForTotal();
    },
    addUnitToRepeater() {
      this.data_units.unshift(this.repeater_unit);
      this.repeater_unit = {unit: null, unit_id: null, number: null, is_active: true};
    },
    deleteUnitFromRepeater(index) {
      this.data_units.splice(index, 1);
    },
    onChangeUnitsRepeater: _.debounce(function (index){
      if (this.data_units[index].unit){
        this.data_units[index].unit_id = this.data_units[index].unit.id;
        if (this.data_units[index].unit_id == this.data.unit_id){
          this.data_units[index].unit = null;
          this.data_units[index].unit_id = null;
        }
      }else{
        this.data_units[index].unit_id = null;
      }
      if(this.data.is_min){
        if (!this.data_units[index].number || (this.data_units[index].number && parseFloat(this.data_units[index].number) < 1)) {
          this.data_units[index].number = 1;
        }
      }

      if (parseFloat(this.data_units[index].number) < 0){
        if(this.data.is_min){
          this.data_units[index].number = 1;
        }else {
          this.data_units[index].number = 0.001;
        }
      }
    }, 300),

    onChangeIsMin: _.debounce(function () {
      if(this.data.is_min){
        this.data_units = this.data_units.map((row)=>{
          if (!row.number || (row.number && parseFloat(row.number) < 1)) {
            row.number = 1;
          }

          return row;
        })
      }
    }, 100),

    onPriorityTaxChanged: _.debounce(function (index){
      if ((this.data_taxes.length - 1) == index){
        this.data_taxes[index].priority = 1;
      }else {
        this.data_taxes[index].priority = this.data_taxes[index + 1].priority + 1
      }

    }, 100),
    handlingPercentageValue(index) {
      let _value_rate = this.data_taxes[index].tax.value_rate;
      this.data_taxes[index].percentage = _value_rate;
    },
    calcPercentageForTotal() {
      // if (this.data_taxes.length > 0) {
      //   this.data.total_taxes = this.$_.sumBy(this.data_taxes, (row) => {
      //     return row.percentage ? parseFloat(row.percentage) : 0;
      //   });
      // } else {
      //   this.data.total_taxes = 0;
      // }
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/9").then((response) => {
        this.data.sku_code = response.data.data.code;
      });
    },

    getPlanMessage() {
      ApiService.get(this.mainRouteDependency + `/check_limits/items`).then((response) => {
        this.PlanMessage = response.data.data;
      });
    },
    emptyItemsList() {
      this.items = [];
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by) {
        let ignore_ids = [];
        this.sub_items.map((row) => {
          if (row.item && row.item.id)
            ignore_ids.push(row.item.id);
        });
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter, ignore_ids: ignore_ids}}).then((response) => {
          this.items = response.data.data;
        });
      }

    },

    addItemRowToList() {
      this.sub_items.unshift(this.sub_items_repeater);
      this.sub_items_repeater = {id: null, item: null, qty: null};
    },
    removeItemRowFromList(index = 0) {
      if (this.sub_items.length > 1) {
        this.sub_items.splice(index, 1);
      }
    },
    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },
    getDataAfterCreateNewItem(object) {
      if (object) {
        if (this.sub_items[0].item) {
          this.addItemRowToList();
        }
        this.sub_items[0].item = object;
      }
    },
    getUnits() {
        ApiService.get(`${this.mainRouteDependency}/units`).then((response) => {
          this.units = response.data.data;
        });

    },
    getStatisticsItem() {
      if (this.idEdit) {
        ApiService.get(`settings/inventory_statistics`, {params: {is_paginate: 0, product_id: this.idEdit}}).then((response) => {
          this.statistics_item = response.data.data;
        });
      }
    },
    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.repeater_taxes.tax = response.data.data.tax;
        if (this.data_taxes[0]) {
          this.data_taxes[0].tax = response.data.data.tax;
          this.data_taxes[0].percentage = response.data.data.tax.value_rate ? response.data.data.tax.value_rate : 0;
          this.data_taxes[0].priority = 1;
        }

      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.composite_items"),
      route: '/items/composite-items'
    }, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);


    this.getCategories();
    this.getBrands();
    this.getTaxes();
    this.getUnits();
    if (this.idEdit) {
      this.getData();
      this.getStatisticsItem();
    } else {

      this.getPlanMessage();

      if (this.data_taxes.length <= 0) {
        this.addToRepeater();
      }
      this.defaultDataForUser();
      if (this.sub_items.length <= 0) {
        this.addItemRowToList();
      }

      this.getCode();
    }

  },
};
</script>


